<template>
  <v-responsive min-height="500">
    <v-row justify="center" align="center" class="h-100">
      <v-col cols="12" v-if="isLoading" class="text-center">
        <v-progress-circular
          :width="4"
          :size="50"
          color="green darken-4"
          indeterminate
        ></v-progress-circular>
        <p class="h3 mt-3">Sending..</p>
      </v-col>
      <v-form
        v-else
        ref="form"
        class="col-10 mx-auto"
        @submit.prevent="handleSubmit"
      >
        <v-row justify="center" align="center" v-if="!isSent">
          <v-col cols="12" md="10">
            <p class="h4 text-left">Enter your registration E-mail to send password reset link</p>
            <v-col cols="12" class="d-flex pl-0">
              <v-text-field
                outlined
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                name="email"
                required
              ></v-text-field>
              <v-btn type="submit" class="white--text mt-1 p-4 ml-8" color="green darken-4" large
                >Reset Password</v-btn
              >
          </v-col>
          </v-col>
        </v-row>
        <v-row justify="center" align="stretch" v-else>
          <v-col cols="10">
            <p class="text-center h3">Email reset token sent, please check your E-mail inbox!</p>
          </v-col>
        </v-row>
      </v-form>
    </v-row>
  </v-responsive>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "password-reset",
  data() {
    return {
      isSent: false,
      email: "",
      emailRules: [v => !!v || "E-mail is required", v => /.+@.+/.test(v) || "E-mail must be valid"]
    };
  },
  computed: {
    ...mapGetters({ isLoading: "enrollmentModule/loading" })
  },
  methods: {
    ...mapActions({
      sendResetTokenEmail: "enrollmentModule/sendResetTokenEmail"
    }),
    async handleSubmit() {
      // validates form input
      if (this.$refs.form.validate()) {
        const response = await this.sendResetTokenEmail({ email: this.email });
        if (response["status"] === 200) {
          this.isSent = true;
          this.$refs.form.reset();
        }
        return;
      }
      return;
    }
  }
};
</script>

